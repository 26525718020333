import Vue from 'vue';

import Router from 'vue-router';

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('./view/index/index'),
    meta: {
      title: '邀请赢奖励'
    }
  },
  {
    path: '/firstRecharge',
    name: 'firstRecharge',
    component: () => import('./view/firstRecharge/firstRecharge'),
    meta: {
      title: '首充'
    }
  },
  {
    path: '/save',
    name: 'save',
    component: () => import('./view/save/save'),
    meta: {
      title: '邀请赢奖励'
    }
  },
  {
    path: '/closeDetail',
    name: 'closeDetail',
    component: () => import('./view/closeDetail/closeDetail'),
    meta: {
      title: '亲密关系'
    }
  },
  {
    path: '/myInvite',
    name: 'myInvite',
    component: () => import('./view/myInvite/myInvite'),
    meta: {
      title: '我的邀请'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('./view/search/search'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/lastInvite',
    name: 'lastInvite',
    component: () => import('./view/lastInvite/lastInvite'),
    meta: {
      title: '下级的详情'
    }
  },
  {
    path: '/withDraw',
    name: 'withDraw',
    component: () => import('./view/withDraw/withDraw'),
    meta: {
      title: '邀请提现'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('./view/record/record'),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: '/heartShop',
    name: 'heartShop',
    component: () => import('./view/heartShop/heartShop'),
    meta: {
      title: '闪耀星辰'
    }
  },
  {
    path: '/magicPlace',
    name: 'magicPlace',
    component: () => import('./view/magicPlace/magicPlace'),
    meta: {
      title: '星际旅行'
    }
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('./view/rule/rule'),
    meta: {
      title: '常见问题'
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('./view/task/task'),
    meta: {
      title: '成长体系'
    }
  },
  {
    path: '/taskRank',
    name: 'taskRank',
    component: () => import('./view/task/taskRank'),
    meta: {
      title: '花花排行榜'
    }
  },
  {
    path: '/newUser',
    name: 'newUser',
    component: () => import('./view/task/newUser'),
    meta: {
      title: '新人专享'
    }
  },
  {
    path: '/huaWithDraw',
    name: 'huaWithDraw',
    component: () => import('./view/withDraw/huaWithDraw'),
    meta: {
      title: '我的钱包'
    }
  },
  {
    path: '/huaRecord',
    name: 'huaRecord',
    component: () => import('./view/record/huaRecord'),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: '/pk/charmList',
    name: 'charmList',
    component: () => import('./view/pkCharm/charmList'),
    meta: {
      title: '查看魅力值'
    }
  },
  {
    path: '/pk/pkRecord',
    name: 'pkRecord',
    component: () => import('./view/pkCharm/pkRecord'),
    meta: {
      title: 'PK记录'
    }
  },
  {
    path: '/pk/recordDetail',
    name: 'recordDetail',
    component: () => import('./view/pkCharm/recordDetail'),
    meta: {
      title: 'PK记录详情'
    }
  },
  {
    path: '/rank/room',
    name: 'room',
    component: () => import('./view/rank/room/room'),
    meta: {
      title: '房间排行榜'
    }
  },
  {
    path: '/rank/radio',
    name: 'radio',
    component: () => import('./view/rank/radio/radio'),
    meta: {
      title: '排行榜'
    }
  },
  {
    path: '/rank/ymScore',
    name: 'ymScore',
    component: () => import('./view/rank/ymScore/ymScore'),
    meta: {
      title: '你说我猜积分榜'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('./view/agreement/agreement'),
    meta: {
      title: '用户须知'
    }
  },
  {
    path: '/agreementIOS',
    name: 'agreementIOS',
    component: () => import('./view/agreement/agreementIOS'),
    meta: {
      title: '用户须知'
    }
  },
  {
    path: '/guildAgreement',
    name: 'guildAgreement',
    component: () => import('./view/agreement/guildAgreement'),
    meta: {
      title: '多乐公会协议'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('./view/recharge/recharge'),
    meta: {
      title: '充值协议'
    }
  },
  {
    path: '/rechargeIOS',
    name: 'rechargeIOS',
    component: () => import('./view/recharge/rechargeIOS'),
    meta: {
      title: '充值协议'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('./view/privacy/privacy'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/privacyIOS',
    name: 'privacyIOS',
    component: () => import('./view/privacy/privacyIOS'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/userinfoList',
    name: 'userinfoList',
    component: () => import('./view/privacy/userinfoList'),
    meta: {
      title: '个人信息收集清单'
    }
  },
  {
    path: '/permissionShow',
    name: 'permissionShow',
    component: () => import('./view/privacy/permissionShow'),
    meta: {
      title: '应用权限申请与使用情况说明'
    }
  },
  {
    path: '/thirdShare',
    name: 'thirdShare',
    component: () => import('./view/privacy/thirdShare'),
    meta: {
      title: '第三方信息共享清单'
    }
  },
  {
    path: '/userAmt',
    name: 'userAmt',
    component: () => import('./view/userAmt/userAmt'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/userAmtIOS',
    name: 'userAmtIOS',
    component: () => import('./view/userAmt/userAmtIOS'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('./view/refund/refund'),
    meta: {
      title: '退款协议'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./view/privacy/logout'),
    meta: {
      title: '账号注销协议'
    }
  },
  {
    path: '/logoutIOS',
    name: 'logoutIOS',
    component: () => import('./view/privacy/logoutIOS'),
    meta: {
      title: '账号注销协议'
    }
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: () => import('./view/upgrade/upgrade'),
    meta: {
      title: '如何升级'
    }
  },
  {
    path: '/giftCash',
    name: 'giftCash',
    component: () => import('./view/giftCash/giftCash'),
    meta: {
      title: '礼物收支'
    }
  },
  {
    path: '/certifiedGame',
    name: 'certifiedGame',
    component: () => import('./view/heroesPlay/certifiedGame'),
    meta: {
      title: '认证'
    }
  },
  {
    path: '/gameList',
    name: 'gameList',
    component: () => import('./view/heroesPlay/gameList'),
    meta: {
      title: '认证'
    }
  },
  {
    path: '/gameCash',
    name: 'gameCash',
    component: () => import('./view/heroesPlay/gameCash'),
    meta: {
      title: '收益报表'
    }
  },
  {
    path: '/incomeRecord',//新收益报表
    name: 'incomeRecord',
    component: () => import('./view/incomeRecord/incomeRecord'),
    meta: {
      title: '收益报表'
    }
  },
  {
    path: '/orderCenter',
    name: 'orderCenter',
    component: () => import('./view/heroesPlay/orderCenter'),
    meta: {
      title: '订单中心'
    }
  },
  {
    path: '/certCenter',
    name: 'certCenter',
    component: () => import('./view/heroesPlay/certCenter'),
    meta: {
      title: '大神中心'
    }
  },
  {
    path: '/appGameCash',
    name: 'appGameCash',
    component: () => import('./view/heroesPlay/appGameCash'),
    meta: {
      title: '收益报表'
    }
  },
  {
    path: '/cosmosCube',
    name: 'cosmosCube',
    component: () => import('./view/cosmosCube/cosmosCube'),
    meta: {
      title: '神秘星球'
    }
  },
  {
    path: '/cosmosIntroduce',
    name: 'cosmosIntroduce',
    component: () => import('./view/cosmosCube/cosmosIntroduce'),
    meta: {
      title: '玩法介绍'
    }
  },
  {
    path: '/recordList',
    name: 'recordList',
    component: () => import('./view/cosmosCube/recordList'),
    meta: {
      title: '拆出记录'
    }
  },
  {
    path: '/goldRecord',
    name: 'goldRecord',
    component: () => import('./view/goldRecord/goldRecord'),
    meta: {
      title: '金币收支'
    }
  },
  {
    path: '/weekStarRank',
    name: 'weekStarRank',
    component: () => import('./view/seventhMoon/weekStarRank'),
    meta: {
      title: '周星榜'
    }
  },
  {
    path: '/weekStarRule',
    name: 'weekStarRule',
    component: () => import('./view/seventhMoon/weekStarRule'),
    meta: {
      title: '周星榜规则'
    }
  },
  {
    path: '/qixiActive',
    name: 'qixiActive',
    component: () => import('./view/qixiActive/qixiActive'),
    meta: {
      title: '七夕甜蜜计划'
    }
  },
  {
    path: '/qixiRule',
    name: 'qixiRule',
    component: () => import('./view/qixiActive/qixiRule'),
    meta: {
      title: '七夕甜蜜计划'
    }
  },
  {
    path: '/guildWaterForm',
    name: 'guildWaterForm',
    component: () => import('./view/guildWater/guildWaterForm'),
    meta: {
      title: '公会流水'
    }
  },
  {
    path: '/guildWaterList',
    name: 'guildWaterList',
    component: () => import('./view/guildWater/guildWaterList'),
    meta: {
      title: '公会流水'
    }
  },
  {
    path: '/userWater',
    name: 'userWater',
    component: () => import('./view/guildWater/userWater'),
    meta: {
      title: '用户流水'
    }
  },
  {
    path: '/userWaterInfo',
    name: 'userWaterInfo',
    component: () => import('./view/guildWater/userWaterInfo'),
    meta: {
      title: '流水详情'
    }
  },
  {
    path: '/guildIndex',
    name: 'guildIndex',
    component: () => import('./view/guildWater/guildIndex'),
    meta: {
      title: '流水首页'
    }
  },
  {
    path: '/packetList',
    name: 'packetList',
    component: () => import('./view/packetList/packetList'),
    meta: {
      title: '红包记录'
    }
  },
  {
    path: '/roomRankList',
    name: 'roomRankList',
    component: () => import('./view/roomRankList/roomRankList'),
    meta: {
      title: ''
    }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('./view/download/download'),
    meta: {
      title: '下载页'
    }
  },
  {
    path: '/exchangeRecord',
    name: 'exchangeRecord',
    component: () => import('./view/exchangeRecord/exchangeRecord'),
    meta: {
      title: '兑换记录'
    }
  },
  {
    path: '/withdrawRecord',//钱包提现记录
    name: 'withdrawRecord',
    component: () => import('./view/exchangeRecord/withdrawRecord'),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: '/luckyBag',//幸运福袋
    name: 'luckyBag',
    component: () => import('./view/luckyBag/luckyBag'),
    meta: {
      title: '糖果乐园'
    }
  },
  {
    path: '/sendRecord',//赠送记录
    name: 'sendRecord',
    component: () => import('./view/sendRecord/sendRecord'),
    meta: {
      title: '赠送记录'
    }
  },
  {
    path: '/rank/athletics',
    name: 'athletics',
    component: () => import('./view/rank/athletics/athletics'),
    meta: {
      title: '竞技排行榜'
    }
  },
  {
    path: '/rank/athleticsLastWeekRank',
    name: 'athleticsLastWeekRank',
    component: () => import('./view/rank/athletics/athleticsLastWeekRank'),
    meta: {
      title: '上周竞技排行榜'
    }
  },
  {
    path: '/rank/athleticsRule',
    name: 'athleticsRule',
    component: () => import('./view/rank/athletics/athleticsRule'),
    meta: {
      title: '竞技排行榜规则'
    }
  },
  {
    path: '/rank/fireStar',
    name: 'FireStar',
    component: () => import('./view/rank/athletics/fireStar'),
    meta: {
      title: '星火燎原'
    }
  },
  {
    path: '/rank/fireStarRule',
    name: 'FireStarRule',
    component: () => import('./view/rank/athletics/fireStarRule'),
    meta: {
      title: '星火燎原规则'
    }
  },
  {
    path: '/starPlan',//星动计划
    name: 'starPlan',
    component: () => import('./view/starPlan/starPlan'),
    meta: {
      title: '星动计划'
    }
  },
  {
    path: '/weekLuckyMan',//每周幸运儿
    name: 'weekLuckyMan',
    component: () => import('./view/weekLuckyMan/weekLuckyMan'),
    meta: {
      title: '周星嘉年华'
    }
  },
  {
    path: '/activeRule',
    name: 'activeRule',
    component: () => import('./view/weekLuckyMan/activeRule'),
    meta: {
        title: '周星嘉年华'
    }
},
{
    path: '/lotteryRecord',
    name: 'lotteryRecord',
    component: () => import('./view/weekLuckyMan/lotteryRecord'),
    meta: {
        title: '周星嘉年华'
    }
},
{
    path: '/luckyPeople',
    name: 'luckyPeople',
    component: () => import('./view/weekLuckyMan/luckyPeople'),
    meta: {
        title: '周星嘉年华'
    }
},
{
    path: '/shareActiveIndex',
    name: 'shareIndex',
    component: () => import('./view/weekLuckyMan/shareActiveIndex'),
    meta: {
        title: '周星嘉年华'
    }
},
{
  path: '/relationNet',
  name: 'relationNet',
  component: () => import('./view/relationNet/relationNet'),
  meta: {
      title: '我的关系网'
  }
},
{
  path: '/relationNetRank',
  name: 'relationNetRank',
  component: () => import('./view/relationNet/relationNetRank'),
  meta: {
      title: '关系榜'
  }
},
{
  path: '/weekParty',//派对周星
  name: 'weekParty',
  component: () => import('./view/weekParty/weekParty'),
  meta: {
    title: '派对周星'
  }
},
{
  path: '/partyActiveRule',
  name: 'partyActiveRule',
  component: () => import('./view/weekParty/partyActiveRule'),
  meta: {
      title: '派对周星'
  }
},
{
  path: '/partyLotteryRecord',
  name: 'partyLotteryRecord',
  component: () => import('./view/weekParty/partyLotteryRecord'),
  meta: {
      title: '派对周星'
  }
},
{
  path: '/partyLuckyPeople',
  name: 'partyLuckyPeople',
  component: () => import('./view/weekParty/partyLuckyPeople'),
  meta: {
      title: '派对周星'
  }
},
{
  path: '/myIncome',
  name: 'myIncome',
  component: () => import('./view/myIncome/myIncome'),
  meta: {
      title: '我的收益'
  }
},
{
  path: '/billManage',
  name: 'BillManage',
  component: () => import('./view/billManage/billManage'),
  meta: {
      title: '账单管理'
  }
},
{
  path: '/withDrawActive',
  name: 'WithDrawActive',
  component: () => import('./view/withDraw/withDrawActive'),
  meta: {
      title: '提现'
  }
},
{
  path: '/exchangeActive',
  name: 'ExchangeActive',
  component: () => import('./view/withDraw/exchangeActive'),
  meta: {
      title: '金币兑换'
  }
},
{
  path: '/infoManage',
  name: 'InfoManage',
  component: () => import('./view/myIncome/infoManage'),
  meta: {
      title: '管理'
  }
},
{
  path: '/bindPhone',
  name: 'BindPhone',
  component: () => import('./view/myIncome/bindPhone'),
  meta: {
      title: '绑定手机号'
  }
},
{
  path: '/bindAlipay',
  name: 'BindAlipay',
  component: () => import('./view/myIncome/bindAlipay'),
  meta: {
      title: '修改支付宝账户'
  }
},
{
  path: '/bindBankCard',
  name: 'BindBankCard',
  component: () => import('./view/myIncome/bindBankCard'),
  meta: {
      title: '修改银行卡'
  }
},
{
  path: '/bindUserInfo',
  name: 'BindUserInfo',
  component: () => import('./view/myIncome/bindUserInfo'),
  meta: {
      title: '绑定收款人信息'
  }
},
{
  path: '/setLevelPassword',
  name: 'SetLevelPassword',
  component: () => import('./view/myIncome/setLevelPassword'),
  meta: {
      title: '设置二级密码'
  }
},
{
  path: '/phoneLogin',
  name: 'PhoneLogin',
  component: () => import('./view/myIncome/phoneLogin'),
  meta: {
      title: '手机号授权'
  }
},
{
  path: '/rechargeIndex',
  name: 'RechargeIndex',
  component: () => import('./view/myIncome/rechargeIndex'),
  meta: {
      title: '充值'
  }
},
{
  path: '/qixi',
  name: 'Qixi',
  component: () => import('./view/qixi/qixi'),
  meta: {
      title: '七夕活动'
  }
},
{
  path: '/qixi_rule',
  name: 'Rule',
  component: () => import('./view/qixi/rule'),
  meta: {
      title: '活动规则'
  }
},
{
  path: '/qixiGift',
  name: 'QixiGift',
  component: () => import('./view/qixi/qixiGift'),
  meta: {
      title: '活动奖励'
  }
},
{
  path: '/my_cp',
  name: 'My_cp',
  component: () => import('./view/qixi/my_cp'),
  meta: {
      title: '我的CP'
  }
},
{
  path: '/cp_wall',
  name: 'Cp_wall',
  component: () => import('./view/qixi/cp_wall'),
  meta: {
      title: 'CP墙'
  }
}
];
//add  router  path //#endregion


routes.forEach(router => {
  // console.log(router,2222);
  router.path = router.path || '/' + (router.name || '');
  // console.log( router.path,4444)
});
// 定义路由信息路由调整前信息 to 代表将要跳转的路由信息 from 代表来自那个页面的信息 next 代表正常跳转
const router = new Router({ routes });
// console.log(router,'route')

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  // console.log( title,555)
  if (title) {
    document.title = title;
  }
  next();
});
//导航后守卫 //#endregionrp
router.afterEach((to, from) => {
  const title = to.meta && to.meta.title;
  // console.log(from,88889)
  if (title) {
    document.title = title;
  }
})


// 渲染出router 路由数据信息

export { router };